import Preloader from "@/components/elements/Preloader"
import { useEffect, useState } from "react"
import AOS from 'aos'
import "public/assets/css/bootstrap.min.css"
import "public/assets/css/animate.min.css"
import "public/assets/css/magnific-popup.css"
import "public/assets/css/fontawesome-all.min.css"
import "public/assets/css/flaticon.css"
import "public/assets/css/odometer.css"
import "public/assets/css/jarallax.css"
import "public/assets/css/swiper-bundle.min.css"
import "public/assets/css/slick.css"
import "public/assets/css/aos.css"
import "public/assets/css/default.css"
import "public/assets/css/style.css"
import "public/assets/css/responsive.css"
import Head from "next/head";
import * as gtag from '../lib/gtag';
import { useRouter } from 'next/router';

function MyApp({ Component, pageProps }) {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
        AOS.init()
    }, [])
    const headTitle = pageProps.headTitle;

    const router = useRouter();

    useEffect(() => {
      const handleRouteChange = (url) => {
        gtag.pageview(url);
      };
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }, [router.events]);
    
    return (
        <>
            <Head>
                    <title>{headTitle ? headTitle : "OHC Chemicals"}</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=yes, minimum-scale=1.0, maximum-scale=5.0" />
                     <meta name="title" content="OHC Medicinal Chemical Trading - High-Quality APIs and Excipients" />
                     <meta 
                        name="description" 
                        content="OHC Medicinal Chemical Trading offers top-quality Active Pharmaceutical Ingredients (APIs), excipients, and pharmaceutical raw materials. With a global network of suppliers, we focus on industry standards for safe and effective medications. Our services include API sourcing, manufacturing, contract pharma, and more." 
                      />
                      <meta 
                        name="keywords" 
                        content="API sourcing, API manufacturers, API suppliers, pharmaceutical raw materials, API production, high-quality APIs, API outsourcing, API development, API intermediates, API contract manufacturing, pharmaceutical excipients, excipient suppliers, excipient manufacturers, excipient sourcing, excipient production, excipients for tablets, excipient compatibility, excipient stability, excipient formulation, excipient innovation, active pharmaceutical ingredients, CDMO, CMO, contract pharma, licensing, patent generic, generic manufacturers, drug innovation, localization, R&D, lifescience, oncology, semaglutide GLP-1, AI healthcare, novel drugs, pharmaceutical co-processed excipients, decarbonization, sustainability, global health, chemicals, biotechnology, biotech"
                      />
                      <meta name="robots" content="index, follow" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="http://www.ohcchemicals.com/" />
                    <meta property="og:title" content="OHC Medicinal Chemical Trading" />
                    <meta property="og:description" content="At OHC Medicinal Chemical Trading, we are dedicated to providing pharmaceutical companies with top-quality APIs. With a global network of trusted suppliers and a focus on industry standards, we ensure safe and effective medications." />
                    <meta property="og:image" content="https://ohc-website-frontend.vercel.app/assets/img/logo.jpeg" />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="http://www.ohcchemicals.com/" />
                    <meta property="twitter:title" content="OHC Medicinal Chemical Trading" />
                    <meta property="twitter:description" content="At OHC Medicinal Chemical Trading, we are dedicated to providing pharmaceutical companies with top-quality APIs. With a global network of trusted suppliers and a focus on industry standards, we ensure safe and effective medications." />
                    <meta property="twitter:image" content="https://ohc-website-frontend.vercel.app/assets/img/logo.jpeg" />
                    
                    <link rel="apple-touch-icon" sizes="180x180" href="/assets/img/favicon" />
                    <link rel="apple-touch-icon" sizes="180x180" href="/assets/img/favicon/apple-touch-icon.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/assets/img/favicon/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/assets/img/favicon/favicon-16x16.png" />
                    <link rel="manifest" href="/assets/img/favicon/site.webmanifest" />
                    <link rel="mask-icon" href="/assets/img/favicon/safari-pinned-tab.svg" color="#5bbad5" />
            </Head>
            {!loading ? (
                <Component {...pageProps} />
            ) : (
                <Preloader />
            )}
        </>
    );
}

export default MyApp
